import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import {Col, DatePicker, Row, Space} from "antd";
import Box from "Components/Box";
import PageTitle from "Components/PageTitle";
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";
import {CalendarEvent} from "Apis/Models/CalendarEvent";
import moment from "moment";
import {
    useCalendarEventsForPeriod,
    useDeleteCalendarEvent,
    useSaveCalendarEvent
} from "Apis/CalendarEventsApi";
import {useOrders} from "Apis/OrderApi";
import Spinner from "Components/Spinner";
import FlexRow from "Components/FlexRow";
import {getInputClasses} from "Utils/CssUtils";
import StyledButton from "Components/StyledButton";
import Modal from "antd/es/modal";
import {Controller, useForm} from "react-hook-form";
import {openSuccessNotification} from "Utils/NotificationUtils";
import Calendar from "Assets/Icons/Calendar";
import Label from "Components/Label";

export type CalendarType = 'dayGridMonth' | 'timeGridWeek';
export const CalendarTypeKey = 'DashboardType';


const CalendarPage = () => {

    const ref = useRef<FullCalendar>(null);
    const [calendarType, setCalendarType] = useState<string>('timeGridWeek');

    const {register, handleSubmit, errors, reset, control} = useForm<CalendarEvent>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>();

    const [from, setFrom] = useState<Date>(moment().startOf('week').toDate());
    const [to, setTo] = useState<Date>(moment().endOf('week').toDate());

    const [eventClones, setEventClones] = useState<CalendarEvent[]>([]);

    const [saveCalendarEvent, {isLoading: isSavingEvent}] = useSaveCalendarEvent();
    const [deleteCalendarEvent, {isLoading: isDeletingEvent}] = useDeleteCalendarEvent();
    const {data: fetchedEvents} = useCalendarEventsForPeriod(from, to);
    const {data: orders, isLoading: isLoadingOrders} = useOrders();

    const nextWeekCallback = () => {
        if (calendarType === 'timeGridWeek') {
            setFrom(moment(from).add(7, 'days').toDate())
            setTo(moment(to).add(7, 'days').toDate())
        } else {
            setFrom(moment(from).add(1, 'month').toDate())
            setTo(moment(to).add(1, 'month').toDate())
        }

        ref.current?.getApi().next();
    }

    const previousWeekCallback = () => {
        if (calendarType === 'timeGridWeek') {
            setFrom(moment(from).subtract(7, 'days').toDate())
            setTo(moment(to).subtract(7, 'days').toDate())
        } else {
            setFrom(moment(from).subtract(1, 'month').toDate())
            setTo(moment(to).subtract(1, 'month').toDate())
        }

        ref.current?.getApi().prev();
    }

    useEffect(() => {
        const draggableEl = document.getElementById("external-events");

        new Draggable(draggableEl!, {
            itemSelector: ".fc-event",
            eventData: function(eventEl) {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("data-order");
                return {
                    title: title,
                    orderId: id
                };
            }
        });
    }, [])

    useEffect(() => {
        const calendarType = localStorage.getItem(CalendarTypeKey);

        if (calendarType && (calendarType === 'dayGridMonth' || calendarType === 'timeGridWeek')) {
            setCalendarType(calendarType);

            if (calendarType === 'timeGridWeek') {
                setFrom(moment().startOf('isoWeek').toDate());
                setTo(moment().endOf('isoWeek').toDate())
            }

            ref.current?.getApi().changeView(calendarType);
        }
    }, [])

    useEffect(() => {
        setEventClones(fetchedEvents ?? [])
        ref.current?.getApi().removeAllEvents()
    }, [fetchedEvents])

    const selectEvent = (props) => {
        const calendarEvent: CalendarEvent = {
            id: props.event.id,
            start: props.event.start,
            end: props.event.end,
            title: props.event.title,
            orderId: props.event.extendedProps.orderId
        }

        setSelectedEvent(calendarEvent);
        reset(calendarEvent);
        setShowModal(true)
    }

    const newEvent = async ({start, end}) => {
        const calendarEvent: CalendarEvent = {
            start: start,
            end: end,
            title: ''
        }

        reset(calendarEvent)
        setSelectedEvent(calendarEvent);
        setShowModal(true)
    }

    const eventResized = async ({event}) => {
        const calendarEvent: CalendarEvent = {
            id: event.id,
            start: event.start,
            end: event.end,
            title: event.title,
            orderId: event.extendedProps.orderId
        }
        await saveCalendarEvent(calendarEvent)

        openSuccessNotification('Success', 'Begivenheden blev gemt')
    }

    const orderDropped = async (props) => {
        const calendarEvent: CalendarEvent = {
            start: props.event.start,
            end: moment(props.event.start).add(1, 'hours').toDate(),
            title: props.event.title,
            orderId: props.event.extendedProps.orderId
        }

        setSelectedEvent(calendarEvent);
        reset(calendarEvent);

        setShowModal(true)
    }

    const saveTitle = async (data: CalendarEvent) => {

        data = {
            ...selectedEvent,
            ...data
        }

        await saveCalendarEvent(data)

        setShowModal(false)

        openSuccessNotification('Success', 'Begivenheden blev gemt')
    }

    const deleteEvent = async () => {
        await deleteCalendarEvent(selectedEvent)

        setShowModal(false)

        openSuccessNotification('Success', 'Begivenheden blev slettet')
    }

    const changeCalendarView = () => {
        const currentView = ref.current?.getApi().view.type;

        const newView = currentView!.includes('timeGridWeek') ? 'dayGridMonth' : 'timeGridWeek';
        setCalendarType(newView);

        localStorage.setItem(CalendarTypeKey, newView);


        ref.current?.getApi().changeView(newView);

        setFrom(ref.current?.getApi().view.currentStart!)
        setTo(ref.current?.getApi().view.currentEnd!)
    }

    return (
        <>
            <PageTitle title={"Kalender"} icon={<Calendar />} />
            <Row gutter={[20, 20]}>
                <Col span={18}>
                    <Box>
                        <FullCalendar
                            // Setup
                            ref={ref}
                            headerToolbar={{
                                end: 'asapenChangeView asapenPrev asapenNext'
                            }}
                            customButtons={{
                                asapenChangeView: {
                                    text: 'Skift visning',
                                    click: changeCalendarView
                                },
                                asapenPrev: {
                                    text: 'Forrige',
                                    click: previousWeekCallback
                                },
                                asapenNext: {
                                    text: 'Næste',
                                    click: nextWeekCallback
                                }
                            }}
                            events={eventClones}
                            plugins={[ timeGridPlugin, dayGridPlugin, interactionPlugin ]}
                            initialView={calendarType}
                            locale={"da"}
                            buttonText={{
                                today: 'I dag',
                                month: 'Måned',
                                week: 'Uge',
                                day: 'Dag',
                                list: 'Liste',
                                prev: "Forrige",
                                next: "Næste"
                            }}
                            firstDay={1}
                            dayHeaderClassNames={"asapen-day-cell"}
                            buttonIcons={{
                                prev: '',
                                next: '',
                            }}
                            allDaySlot={false}
                            eventBackgroundColor={'#F39324'}
                            eventBorderColor={'transparent'}

                            // Editing
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            selectMinDistance={15}
                            eventDurationEditable={true}
                            eventStartEditable={true}
                            droppable={true}
                            select={newEvent}
                            eventResize={eventResized}
                            eventDrop={eventResized}
                            eventReceive={orderDropped}
                            eventClick={selectEvent}
                            dateClick={x => newEvent({start: x.date, end: x.date})}
                        />
                    </Box>
                </Col>
                <Col span={6}>
                    <Box>
                        <b style={{color: 'rgba(147, 147, 147, 0.5)'}}>
                            Åbne ordre
                        </b>
                        <hr/>
                        <div id="external-events">
                            {isLoadingOrders && <FlexRow justify={"center"} align={"center"}><Spinner /></FlexRow>}
                            {orders?.map(order => (
                                <div className="fc-event asapen-order-event" title={order.title} data-order={order.id.toString()} key={order.id.toString()}>
                                    {order.title}
                                </div>
                            ))}
                        </div>
                    </Box>
                </Col>
            </Row>
            <Modal visible={showModal} title={"Begivenhed"}
                   onCancel={() => setShowModal(false)}
                   footer={false}
            >
                <form onSubmit={handleSubmit(saveTitle)}>
                    <Space className={"w-100"} size={"large"} direction={"vertical"}>

                        <div>
                            <Label>
                                Fra
                            </Label>
                            <Controller name={"start"} control={control} defaultValue={null} rules={{required: true}}
                                render={props => (
                                    <DatePicker
                                        className={getInputClasses(errors.start)}
                                        placeholder={"Fra"}
                                        showTime
                                        minuteStep={15}
                                        format={"DD-MM-YYYY HH:mm"}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <Label>
                                Til
                            </Label>
                            <Controller name={"end"} control={control} defaultValue={null} rules={{required: true}}
                                render={props => (
                                    <DatePicker
                                        format={"DD-MM-YYYY HH:mm"}
                                        showTime
                                        minuteStep={15}
                                        placeholder={"Til"}
                                        className={getInputClasses(errors.end)}
                                        value={props.value ? moment(props.value) : null}
                                        onChange={(e => {
                                            props.onChange(e)
                                        })}
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <Label>
                                Beskrivelse
                            </Label>
                            <input type={"text"} placeholder={"Titel"} name="title" className={getInputClasses(errors.title)} ref={register({required: true})} />
                        </div>

                        <FlexRow justify={"space-between"}>
                            <div>
                                {selectedEvent?.id && (
                                    <StyledButton loading={isDeletingEvent} color={'danger'} onClick={deleteEvent}>Slet</StyledButton>
                                )}
                            </div>
                            <Space>
                                <StyledButton onClick={() => setShowModal(false)}>Afbryd</StyledButton>
                                <StyledButton loading={isSavingEvent} color={"primary"} type={"submit"}>Gem</StyledButton>
                            </Space>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default CalendarPage
