import {useForm, Controller} from "react-hook-form";
import React, {useEffect, useState} from "react";
import FlexRow from "Components/FlexRow";
import styles from 'Pages/Projects/Offers/OfferForm.module.scss';
import {Col, DatePicker, Dropdown, Modal, Row, Select, Space, Upload} from "antd";
import moment from 'moment';
import StyledButton from "Components/StyledButton";
import {addVat, getNumberAsCurrency, getVat} from "Utils/NumberUtils";
import PaperLayout from "Components/PaperLayout";
import {useCustomers} from "Apis/CustomerApi";
import {useAddressSuggestionMenu} from "Hooks/UseAddressSuggestions";
import {getPaperInputClasses} from "Utils/CssUtils";
import PriceCalculator from "Pages/Projects/Offers/Components/PriceCalculator";
import {OfferModel} from "Apis/Models/OfferModel";
import {useCloneOffer, useDeleteOffer, useOfferReadyForApproval, useSaveOffer} from "Apis/OfferApi";
import Ribbon from "Components/Ribbon";
import { useHistory } from "react-router-dom";
import {openErrorNotification, openSuccessNotification, openWarningNotification} from "Utils/NotificationUtils";
import {getOfferTypeAsString, getOfferStateAsString, getOfferStateColor} from "Utils/OfferUtils";
import CustomerForm from "Pages/Projects/Customers/Forms/CustomerForm";
import {useDefaultOfferText} from "Apis/TextApi";
import {useUserProvider} from "Providers/UserProvider";
import Notice from "Components/Notice";
import {getDateWithTime} from "Utils/DateUtils";
import {downloadOfferFile, useDeleteOfferFile, useOfferFiles, useUploadOfferFile} from "Apis/OfferFileApi";
import {toBase64} from "Utils/Base64Utils";
import Spinner from "Components/Spinner";
import Report from "Assets/Icons/Report";
import {useIsLoading} from "Hooks/UseIsLoading";
import DocumentContainer from "Components/Document/DocumentContainer";

interface Props {
    type: 'Offer' | 'Estimate',
    offer?: OfferModel
}
const OfferForm = ({type, offer: offerFromProps}: Props) => {

    const history = useHistory();
    const user = useUserProvider();
    const [offer, setOffer] = useState<OfferModel | undefined>(offerFromProps);

    const [customerSearch, setCustomerSearch] = useState<string>('');
    const [showPriceModal, setShowPriceModal] = useState<boolean>(false);
    const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
    const [showCopyAddress, setShowCopyAddress] = useState<boolean>(false);

    const formMethods = useForm<OfferModel>({mode: 'all'});
    const {register, handleSubmit, control, errors, setValue, getValues, reset, trigger, watch} = formMethods;

    const {data: customers} = useCustomers();
    const [saveOfferMutation, {isLoading}] = useSaveOffer();
    const [readyForApprovalMutation, {isLoading: isLoadingApproval}] = useOfferReadyForApproval();
    const {data: offerFiles} = useOfferFiles(offerFromProps?.id! ?? offer?.id, !!offerFromProps || !!offer)
    const {data: defaultOfferText } = useDefaultOfferText(user.userContext?.lastOrganizationId!, true)
    const [deleteOfferMutation, {isLoading: isDeletingOffer}] = useDeleteOffer();
    const [uploadFileMutation] = useUploadOfferFile();
    const [cloneOfferMutation, { isLoading: isCloningOffer }] = useCloneOffer();


    useEffect(() => {
        reset(offer)
    }, [reset, offer, customers, trigger])

    const dateSelected = async (e) => {
        setValue('expirationDate', e.add(1, 'month'));
    }

    const [invoicingAddressSuggestions, invoicingAddressSuggestionMenu, searchForInvoicingAddress] = useAddressSuggestionMenu(async (address) => {
        setValue('invoicingAddress.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('invoicingAddress.city', address.adresse.postnrnavn)
        setValue('invoicingAddress.zipCode', address.adresse.postnr)
        searchForInvoicingAddress('')
    });
    const [deliveryAddressSuggestions, deliveryAddressSuggestionMenu, searchForDeliveryAddress] = useAddressSuggestionMenu(async (address) => {
        setValue('deliveryAddress.street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('deliveryAddress.city', address.adresse.postnrnavn)
        setValue('deliveryAddress.zipCode', address.adresse.postnr)
        searchForDeliveryAddress('')
    });

    const customerSelected = (id) => {
        setCustomerSearch('')
        const customer = customers!.find(x => x.id === parseInt(id));

        if (customer) {
            setValue('customerId', customer.id);
            setValue('invoicingAddress.customerName', customer.name);
            setValue('invoicingAddress.att', customer.att);
            setValue('invoicingAddress.street', customer.street);
            setValue('invoicingAddress.city', customer.city)
            setValue('invoicingAddress.zipCode', customer.zipCode)
            setValue('invoicingAddress.vatNumber', customer.vatNumber)
            setValue('invoicingAddress.email', customer.email)
            setValue('invoicingAddress.ean', customer.ean)
        }
    }

    const copyInvoicingAddress = () => {
        setValue('deliveryAddress.street', getValues('invoicingAddress.street'));
        setValue('deliveryAddress.city', getValues('invoicingAddress.city'))
        setValue('deliveryAddress.zipCode', getValues('invoicingAddress.zipCode'))

        trigger();
    }

    const save = async (data: OfferModel) => {
        if (offer && offer.state === 'WaitingForApproval') {
            openWarningNotification('Hov hov', 'Du kan ikke ændre et tilbud der afventer godkendelse')
        } else {
            if (offer) {
                data.id = offer.id;
                data.state = offer.state;
            }

            data.offerType = type;

            return await saveOfferMutation(data);
        }
    }

    const deleteDraft = async () => {
        await deleteOfferMutation(offer!);

        openSuccessNotification('Success', `${getOfferTypeAsString(offer!.offerType)} blev slettet`)

        history.goBack();
    }

    const saveDraft = async (data: OfferModel) => {
        const response = await save(data)

        if (response) {
            openSuccessNotification('Success', 'Informationen blev gemt')

            if (!offer) {
                setOffer(response)
            }
        }
    }

    const showValidationFailed = () => {
        openErrorNotification('Der mangler informationer', 'Tjek at alle påkrævede felter er udfyldte, tjek også varelinjerne under tilbudsberegneren')
    }

    const usePrice = async (price: number) => {
        setValue('priceExVat', price);

        setShowPriceModal(false);
    }

    const openPriceCalculator = async () => {
        setShowPriceModal(true)
    }

    const saveAndSendToApproval = async (data: OfferModel) => {
        const response = await save(data);

        if (response) {
            await readyForApprovalMutation(response);

            openSuccessNotification('Success', `${getOfferTypeAsString(response.offerType)} blev sendt til godkendelse`)

            history.push(`/projects/offers/${response!.id}`)
        }
    }

    const uploadFileHandler = async (e) => {
        const base64 = await toBase64(e.file)

        await uploadFileMutation({
            description: e.file.name,
            offerId: offerFromProps?.id ?? offer?.id,
            fileContent: base64,
            contentType: e.file.type
        })

        openSuccessNotification('Success', 'Filen blev uploadet')
    }

    const cloneOffer = async () => {
        const response = await cloneOfferMutation(offer!);

        if (response) {
            openSuccessNotification('Success', `${getOfferTypeAsString(response.offerType)} blev kopieret`)

            history.push(`/projects/offers/${response!.id}`)
        }
    }

    return (
        <>
            <DocumentContainer>
            <PaperLayout beforePaper={
                <div>
                    {(!offer || offer.state === 'Draft' || offer.state === 'Rejected') && (
                        <FlexRow justify={"space-between"}>
                            <div>
                                {offer?.state === 'Draft' && (
                                    <StyledButton disabled={isLoading || isLoadingApproval || isDeletingOffer} loading={isDeletingOffer} color={"danger"} onClick={handleSubmit(deleteDraft)}>Slet kladde</StyledButton>
                                )}
                            </div>
                            <Space>
                                {offer?.state === 'Draft' && (
                                    <Upload customRequest={uploadFileHandler} showUploadList={false}>
                                        <StyledButton loading={isLoading}>Upload fil</StyledButton>
                                    </Upload>
                                )}
                                <StyledButton disabled={isLoading || isLoadingApproval || isDeletingOffer || isCloningOffer} loading={isCloningOffer} onClick={cloneOffer}>Kopier</StyledButton>
                                <StyledButton disabled={isLoading || isLoadingApproval || isDeletingOffer || isCloningOffer} loading={isLoading || isLoadingApproval} onClick={handleSubmit(saveDraft)}>Gem</StyledButton>
                                <StyledButton disabled={isLoading || isLoadingApproval || isDeletingOffer || isCloningOffer} loading={isLoading || isLoadingApproval} onClick={handleSubmit(saveAndSendToApproval)} color={"primary"}>Send til godkendelse</StyledButton>
                            </Space>
                        </FlexRow>
                    )}
                    {offer?.approval && offer.approval.approvedAt && (
                        <Notice type={offer.approval.isApproved ? 'Success' : 'Danger'}>
                            <FlexRow direction={"column"} align={"start"}>
                                <b>
                                    {offer.approval.isApproved ? 'Godkendt' : 'Afvist'} {getDateWithTime(offer.approval.approvedAt)}
                                </b>
                                <span>
                                {offer?.approval?.reason}
                            </span>
                            </FlexRow>
                        </Notice>
                    )}
                    <div className={styles.uploadedFilesContainer}>
                        {offerFiles?.map(file => (
                            <OfferFile file={file}/>
                        ))}
                    </div>
                </div>
            }>
                <Ribbon text={getOfferStateAsString(offer)} color={getOfferStateColor(offer)}  />
                <form onSubmit={handleSubmit(saveDraft, showValidationFailed)}>
                    <FlexRow justify={"space-between"}>

                        <div style={{width: '40%', fontSize: '.9em'}}>
                            <Row gutter={[5, 5]}>
                                <Col span={24}>
                                    <Controller name={"customerId"} defaultValue={null} control={control} render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            className={getPaperInputClasses(errors.customerId)}
                                            bordered={false}
                                            showSearch
                                            value={value}
                                            placeholder={'Vælg kunde'}
                                            filterOption={false}
                                            onSelect={customerSelected}
                                            onSearch={(value) => setCustomerSearch(value.toLowerCase())}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <div onClick={() => setShowCustomerModal(true)} style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, cursor: 'pointer' }}>
                                                        <span>
                                                            + Opret kunde
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            {customers?.filter(x => x.name.toLowerCase().includes(customerSearch)).map((customer) => (
                                                <Select.Option key={customer.id} value={customer.id}>{customer.name}</Select.Option>
                                            ))}
                                        </Select>
                                    )} />
                                    <input type="hidden" ref={register} name={"invoicingAddress.customerName"}/>
                                </Col>
                            </Row>

                            <Dropdown visible={invoicingAddressSuggestions.length > 0} overlay={invoicingAddressSuggestionMenu}>
                                <input className={getPaperInputClasses(errors.invoicingAddress?.street, true)} onChange={e => searchForInvoicingAddress(e.target.value)} name={"invoicingAddress.street"} ref={register} placeholder={"Vej"} />
                            </Dropdown>
                            <Row gutter={5}>
                                <Col span={12}>
                                    <input className={getPaperInputClasses(errors.invoicingAddress?.zipCode, true)} name={"invoicingAddress.zipCode"} ref={register} placeholder={"Postnr"} />
                                </Col>
                                <Col span={12}>
                                    <input className={getPaperInputClasses(errors.invoicingAddress?.city, true)} name={"invoicingAddress.city"} ref={register} placeholder={"By"} />
                                </Col>
                            </Row>
                            <div className={getPaperInputClasses(errors.invoicingAddress?.vatNumber, true)}>
                                <div>CVR: </div>
                                <input name={"invoicingAddress.vatNumber"} ref={register} placeholder={"CVR"} />
                            </div>
                            <div className={getPaperInputClasses(errors.invoicingAddress?.att, true)}>
                                <div>ATT: </div>
                                <input name={"invoicingAddress.att"} ref={register} placeholder={"Att"} />
                            </div>
                            <div className={getPaperInputClasses(errors.invoicingAddress?.ean, true)}>
                                <div>EAN: </div>
                                <input name={"invoicingAddress.ean"} ref={register} placeholder={"EAN"} />
                            </div>
                            <div className={getPaperInputClasses(errors.invoicingAddress?.email, true)}>
                                <div>E-Mail: </div>
                                <input name={"invoicingAddress.email"} ref={register} placeholder={"Email"} />
                            </div>
                        </div>

                        <div>
                            <img src="/images/Logo.png" width={250} alt="ELi A/S Badge"/>
                        </div>
                    </FlexRow>

                    <div style={{width: '40%', fontSize: '.9em', padding: '10px 0'}} onMouseEnter={() => {setShowCopyAddress(true)}} onMouseLeave={() => {setShowCopyAddress(false)}}>
                        <FlexRow justify={"space-between"}>
                            <b>Installationsadresse</b>
                            <span onClick={copyInvoicingAddress} style={showCopyAddress ? {fontSize: '.8em', cursor: 'pointer'} : {display: 'none'}}>Kopier fakturaadresse</span>
                        </FlexRow>
                        <Dropdown visible={deliveryAddressSuggestions.length > 0} overlay={deliveryAddressSuggestionMenu}>
                            <input className={getPaperInputClasses(errors.deliveryAddress?.street, true)} onChange={e => searchForDeliveryAddress(e.target.value)} name={"deliveryAddress.street"} ref={register} placeholder={"Vej"} />
                        </Dropdown>
                        <Row gutter={5}>
                            <Col span={12}>
                                <input className={getPaperInputClasses(errors.deliveryAddress?.zipCode, true)} name={"deliveryAddress.zipCode"} ref={register} placeholder={"Postnr"} />
                            </Col>
                            <Col span={12}>
                                <input className={getPaperInputClasses(errors.deliveryAddress?.city, true)} name={"deliveryAddress.city"} ref={register} placeholder={"By"} />
                            </Col>
                        </Row>
                    </div>

                    <select disabled style={{background: 'none', fontWeight: 600, fontSize: '1.3rem', color: 'black'}} defaultValue={type}>
                        <option value="Offer">Tilbud</option>
                        <option value="Estimate">Overslag</option>
                    </select>

                    <hr className={styles.seperator}/>

                    <input className={getPaperInputClasses(errors.title)} style={{fontWeight: 600}} ref={register} name={'title'} placeholder={"Overskrift"} />

                    <FlexRow justify={"space-between"}>
                        <div className={styles.defaultTextContainer}>
                            {defaultOfferText}
                        </div>

                        <div className={"flex column"}>
                            <FlexRow justify={"space-between"} className={'paper-input'}>
                                Rekvistionsnr:
                                <input style={{float: 'right', clear: 'both', direction: 'rtl'}} name={"requisitionNumber"} ref={register}/>
                            </FlexRow>
                            <FlexRow justify={"space-between"} className={errors.date ? 'paper-input small error' : 'paper-input'}>
                                Dato:
                                <Controller name={"date"} control={control} defaultValue={null}
                                    render={props => (
                                        <DatePicker bordered={false}
                                            size={"small"}
                                            format={"DD-MM-YYYY"}
                                            style={{fontSize: '1em'}}
                                            value={props.value ? moment(props.value) : null}
                                            onChange={(e => {
                                                props.onChange(e?.toDate())
                                                dateSelected(e);
                                            })}
                                        />
                                    )}
                                />
                            </FlexRow>
                            <span>
                                <FlexRow justify={"space-between"} className={errors.expirationDate ? 'paper-input small error' : 'paper-input'}>
                                    Gyldig indtil:
                                    <Controller name={'expirationDate'} control={control}
                                        defaultValue={null}
                                        render={props => (
                                            <DatePicker bordered={false}
                                                format={"DD-MM-YYYY"}
                                                size={"small"}
                                                value={props.value ? moment(props.value) : null}
                                                onChange={(e => {
                                                    props.onChange(e?.toDate())
                                                })}
                                            />
                                        )}
                                    />
                                </FlexRow>
                            </span>
                        </div>
                    </FlexRow>

                    <hr className={styles.seperator}/>

                    <div className={styles.descriptionContainer}>
                        <b>Specifikation af {getOfferTypeAsString(type)}</b>

                        <textarea name="description" className={getPaperInputClasses(errors.description)} rows={7} ref={register} />

                        <br/><br/>


                        <br/>
                        <div>
                            Via ELi A/S medlemskab af Tekniq arbejdsgiverne, er du som privatforbruger
                            omfattet af Tekniq’s garantiordning. Ligeledes gælder Tekniq’s standardforbehold for
                            el og vvs af 2007. <a href="https://www.tekniq.dk/forbruger">Klik her for at se
                            mere</a>
                        </div>
                    </div>

                    <hr className={styles.seperator}/>

                    <FlexRow justify={"end"} align={'end'} direction={"column"}>
                        <div className={`clickable paper-field`} style={{width: '30%'}} onClick={openPriceCalculator}>
                            <FlexRow justify={"space-between"}>
                                <span>Subtotal</span>
                                <input type="hidden" ref={register} name={"priceExVat"}/>
                                <span>{(getNumberAsCurrency(watch('priceExVat')))} Kr.</span>
                            </FlexRow>
                            <FlexRow justify={"space-between"}>
                                <span>Moms</span>
                                <span>{(getNumberAsCurrency(getVat(watch(('priceExVat')))))} Kr.</span>
                            </FlexRow>
                            <FlexRow justify={"space-between"}>
                                <span><b>I alt</b></span>
                                <span>{(getNumberAsCurrency(addVat(watch('priceExVat'))))} Kr.</span>
                            </FlexRow>
                        </div>
                    </FlexRow>
                </form>
            </PaperLayout>
            </DocumentContainer>

            <Modal
                visible={showPriceModal}
                onCancel={() => setShowPriceModal(false)}
                footer={false}
                width={'80%'}
                destroyOnClose={false}
                forceRender={true}
            >
                <PriceCalculator offer={offer!} price={getValues('priceExVat')} onOk={usePrice} formMethods={formMethods} />
            </Modal>

            <Modal
                visible={showCustomerModal}
                onCancel={() => setShowCustomerModal(false)}
                footer={false}
                width={'70%'}
            >
                <CustomerForm onSave={() => {
                    setShowCustomerModal(false);
                }} />

            </Modal>
        </>
    )
}

const OfferFile = ({file}) => {

    const [loading, downloadFile] = useIsLoading(() => downloadOfferFile(file.offerId, file.id, file.description))
    const [deleteFileMutation, {isLoading: isDeletingFile}] = useDeleteOfferFile();

    const removeFile = async () => {
        await deleteFileMutation({offerId: file.offerId, fileId: file.id})
    }

    return (
        <div className={styles.uploadedFileContainer}>
            <FlexRow justify={"end"} onClick={removeFile} className={"clickable"} >X</FlexRow>
            <div onClick={() => downloadFile()} className={styles.uploadedFile}>
                {loading || isDeletingFile ? <Spinner /> : <Report />}
                <div className={styles.uploadedFileDescription}>
                    {isDeletingFile ? 'Sletter fil' : file.description}
                </div>
            </div>
        </div>
    )
}

export default OfferForm;
