import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {getInputClasses} from "Utils/CssUtils";
import {Col, Dropdown, Row} from "antd";
import StyledButton from "Components/StyledButton";
import FlexRow from "Components/FlexRow";
import Label from "Components/Label";
import GroupLabel from "Components/GroupLabel";
import {DawaAutocompleteModel} from "Apis/Models/DawaAutocompleteModel";
import {useAddressSuggestionMenu} from "Hooks/UseAddressSuggestions";
import {openSuccessNotification} from "Utils/NotificationUtils";
import {SupplierModel} from "Apis/Models/SupplierModel";
import {useSaveSupplier} from "Apis/SupplierApi";

interface Props {
    supplier?: SupplierModel;
    onSave?: (supplier: SupplierModel) => void;
}

const SupplierForm = ({supplier, onSave}: Props) => {

    const {register, handleSubmit, errors, setValue, reset} = useForm<SupplierModel>();

    const [saveSupplierMutation, {isLoading}] = useSaveSupplier();
    
    const [addressSuggestions, addressSuggestionsMenu, searchAddress, clearAddressSuggestions] = useAddressSuggestionMenu((address: DawaAutocompleteModel) => {
        setValue('street', `${address.adresse.vejnavn} ${address.adresse.husnr} ${address.adresse.etage ?? ''} ${address.adresse.dør ?? ''}`);
        setValue('city', address.adresse.postnrnavn)
        setValue('zipCode', address.adresse.postnr)
    })

    useEffect(() => {
        reset(supplier)
    }, [reset, supplier])


    const onSubmitHandler = async (data: SupplierModel) => {
        const savedSupplier = await saveSupplierMutation(data);

        openSuccessNotification('Success', 'Leverandøren blev gemt')

        if (onSave) {
            await onSave(savedSupplier!);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>

            <input type={"hidden"} name={"id"} ref={register} />

            <Row gutter={[20, 20]}>
                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            Firmanavn
                        </Label>
                        <input className={getInputClasses(errors.name)} name={"name"} ref={register({required: true})} />
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div>
                        <Label>
                            CVR Nummer
                        </Label>
                        <input className={getInputClasses(errors.vatNumber)} name={"vatNumber"} ref={register({required: false, maxLength: 8})} />
                    </div>
                </Col>
                <Col xs={24}>
                    <hr/>

                    <GroupLabel>Stamdata</GroupLabel>
                </Col>
                <Col xs={24} sm={8}>
                <div>
                        <Label>
                            Adresse
                        </Label>
                        <Dropdown visible={addressSuggestions.length > 0} overlay={addressSuggestionsMenu}>
                            <input className={getInputClasses(errors.street)} onChange={e => searchAddress(e.target.value)} onBlur={() => setTimeout(() => clearAddressSuggestions(), 100)} name={"street"} ref={register({required: true})} />
                        </Dropdown>
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            Postnummer
                        </Label>
                        <input className={getInputClasses(errors.zipCode)} name={"zipCode"} ref={register({required: true, minLength: 3, maxLength: 4})} />
                    </div>
                </Col>
                <Col xs={24} sm={8}>
                    <div>
                        <Label>
                            By
                        </Label>
                        <input className={getInputClasses(errors.city)} name={"city"} ref={register({required: true})} />
                    </div>
                </Col>

                 <Col xs={24}>
                    <div>
                        <Label>
                            Bankoplysninger (Reg. nr. og kontonr. ud i et)
                        </Label>
                        <input className={getInputClasses(errors.bankAccountNo)} name={"bankAccountNo"} ref={register({required: false})} />
                    </div>
                </Col>

                <Col xs={24}>
                    <FlexRow justify={"end"}>
                        <StyledButton color={"primary"} disabled={isLoading} loading={isLoading} type={"submit"}>Gem</StyledButton>
                    </FlexRow>
                </Col>
            </Row>


        </form>
    )
}

export default SupplierForm;
